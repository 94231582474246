.testimonial-carousel {

    top: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 50px 0; /* Adjust padding for better responsiveness */
    overflow: hidden; /* Hide overflowing content */

}

/* TestimonialCarousel.css */

* {
    box-sizing: border-box;
}

.testimonial {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 10px; /* Adjust margins */
    gap: 1.5rem;
}

.testimonial-image img {
    width: 250px; /* Adjust image size */
    height: 250px; /* Adjust image size */
    border-radius: 50%;
}

.testimonial-text {
    max-width: 500px; /* Adjust maximum width for better responsiveness */
    text-align: center; /* Center-align text */
}

.quote {

    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 10px; /* Add margin to separate quote and author */
}

.author {
     /* Make author name bold */

    font-size: 1.3rem;
}

.arrow {
    padding: 8px 60px;
    font-size: 7rem; /* Adjust arrow size */
    border: none;
    background-color: transparent;
    color: #d8131c;
    cursor: pointer;
    transition: color 0.3s ease; /* Add transition effect */
}

.arrow:hover {
    color: darkred;
}

.arrow.left {
    margin-right: 30px; /* Adjust margin */
    margin-left: 150px;
}

.arrow.right {
    margin-left: 30px; /* Adjust margin */
    margin-right: 150px;
}

/* Media queries for responsiveness */

/* For screens smaller than 768px in width */



@media screen and (max-width: 1500px) {
    .testimonial-carousel {

        padding: 20px 0 ; /* Adjust padding */
    }
    .arrow.left {
        margin-right: 30px; /* Adjust margin */
        margin-left: 100px;
    }

    .arrow.right {
        margin-left: 30px; /* Adjust margin */
        margin-right: 100px;
    }
    .testimonial {
        margin: 10px 0; /* Adjust margin */
        gap: 1rem;

    }
    .quote {

        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 10px; /* Add margin to separate quote and author */
    }

    .arrow {
        font-size: 3rem; /* Adjust arrow size */
    }
    .testimonial-image img {
        width: 150px; /* Adjust image size */
        height: 150px; /* Adjust image size */
        border-radius: 50%;
    }
    .author {
        /* Make author name bold */

        font-size:1rem;
    }
}
@media screen and (max-width: 1100px) {
    .testimonial-carousel {

        padding: 20px 0 ; /* Adjust padding */
    }
    .arrow.left {
        margin-right: 30px; /* Adjust margin */
        margin-left:50px;
    }

    .arrow.right {
        margin-left: 30px; /* Adjust margin */
        margin-right: 50px;
    }
    .testimonial {
        margin: 10px 0; /* Adjust margin */
        gap: 1rem;

    }
    .quote {

        font-weight: bold;
        font-size: 1.3rem;
        margin-bottom: 10px; /* Add margin to separate quote and author */
    }

    .arrow {
        font-size: 3rem; /* Adjust arrow size */
    }
    .testimonial-image img {
        width: 120px; /* Adjust image size */
        height: 120px; /* Adjust image size */
        border-radius: 50%;
    }
    .author {
        /* Make author name bold */

        font-size:.8rem;
    }
}

@media screen and (max-width: 840px) {
    .testimonial-carousel {

        padding: 20px 0 ; /* Adjust padding */
    }

    .testimonial {
        margin: 10px 0; /* Adjust margin */
        gap: .3rem;
        flex-direction: column;

    }
    .quote {

        font-weight: bold;
        font-size: 1.6rem;
        margin-bottom: 10px; /* Add margin to separate quote and author */
    }

    .arrow {
        font-size: 3rem; /* Adjust arrow size */
        padding: 8px 30px;
    }

    .arrow.left {
        margin-right: 10px; /* Adjust margin */
        margin-left:30px;
    }

    .arrow.right {
        margin-left: 10px; /* Adjust margin */
        margin-right: 30px;
    }
    .testimonial-image img {
        width: 150px; /* Adjust image size */
        height: 150px; /* Adjust image size */
        border-radius: 50%;
    }
    .author {
         /* Make author name bold */

        font-size:1.3rem;
    }
}


@media screen and (max-width: 700px) {
    .testimonial-carousel {

        padding: 20px 0 ; /* Adjust padding */
    }

    .testimonial {
        margin: 10px 0; /* Adjust margin */
        gap: 0;

    }
    .quote {

        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 10px; /* Add margin to separate quote and author */
    }

    .arrow {
        font-size: 3rem; /* Adjust arrow size */
        padding: 8px 40px;
    }
    .arrow.left {
        margin-right: 10px; /* Adjust margin */
        margin-left:30px;
    }

    .arrow.right {
        margin-left: 10px; /* Adjust margin */
        margin-right: 30px;
    }
    .testimonial-image img {
        width: 130px; /* Adjust image size */
        height: 130px; /* Adjust image size */
        border-radius: 50%;
    }
    .author {

        font-size:.85rem;
    }
}

@media screen and (max-width: 550px) {
    .testimonial-carousel {

        padding: 20px 0 ; /* Adjust padding */
    }

    .testimonial {
        margin: 10px 0; /* Adjust margin */
        gap: 0;

    }
    .quote {

        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 10px; /* Add margin to separate quote and author */
    }
    .arrow.left {
        margin-right: 10px; /* Adjust margin */
        margin-left:10px;
    }

    .arrow.right {
        margin-left: 10px; /* Adjust margin */
        margin-right: 10px;
    }
    .arrow {
        font-size: 2rem; /* Adjust arrow size */
        padding: 8px 20px;
    }

    .testimonial-image img {
        width: 130px; /* Adjust image size */
        height: 130px; /* Adjust image size */
        border-radius: 50%;
    }
    .author {

        font-size:.7rem;
    }
}

@media screen and (max-width: 380px){
    .arrow {
        font-size: 1.7rem; /* Adjust arrow size */
        padding: 8px 20px;
    }

    .author {

        font-size:.8rem;
    }
    .quote {

        font-weight: bold;
        font-size: .9rem;
        margin-bottom: 10px; /* Add margin to separate quote and author */
    }
}
