/* Import Lexend Deca font from Google Fonts */


/* Apply the font to the entire form */
.email-form {
    font-family: 'Lexend Deca', sans-serif;
    max-width: 600px;
    width: 40%;
    height:auto;
    margin: 0 100px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff0f0;
}

.email-form input,
.email-form textarea {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 16px;
    background-color: #ffe6e6;
}

.email-form input:focus,
.email-form textarea:focus {
    border-color: #ffcccc;
    outline: none;
    background-color: #ffe6e6;
}

.email-form textarea {
    height: 150px;
}

.email-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #cc0000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 25px;
}

.email-form button:hover {
    background-color: #990000;
}

/* Responsive design */
@media (max-width: 1100px) {
    .email-form {
        padding: 10px;
        margin: 20px;
    }

    .email-form input,
    .email-form textarea {
        font-size: 14px;
        padding: 8px;
    }

    .email-form button {
        padding: 8px;
        font-size: 14px;
    }
    .email-form {
        font-family: 'Lexend Deca', sans-serif;
        max-width: 600px;
        width: 70%;
        height:auto;
        margin: 0 3px;
        padding: 1px;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        background-color: #fff0f0;
        top: 20px;
    }
}
