  .App {
    text-align: center;
    font-family: 'Lexend Deca', sans-serif;

  }

  .email-form-section{
    margin-top:70px
  }

  /* Add this CSS for the flashing background effect */
  @keyframes flashBackground {
    0% {
      background-color: #d8131c; /* Start color */
    }
    100% {
      background-color: black; /* End color */
    }
  }

  .story-section {
    justify-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .App-logo {
    width: 70%; /* Adjust this value as needed */

    height: 50%; /* This will maintain the aspect ratio */
    pointer-events: none;
    overflow: hidden;
  }

  .App-header {
    background-color: #ffffff;
    min-height: 100svh; /* This will make the header take up at least the full height of the viewport */
    width: 100%; /* This will make the header take up the full width of the viewport */

    height: 100%;
    top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(2vw + 10px);
    color: Black;

    overflow: hidden;/* Prevent horizontal scrolling */
  }


  .title-image {
    width: 80%; /* Make the image take the full width of the container */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Add some space between the title image and the story image */
    margin-top:60px;
  }

  .contact-image {
    width: 40%; /* Make the image take the full width of the container */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Add some space between the title image and the story image */
  }

  .story-text {
    font-size: 1.7rem;
    line-height: 1.6;
    color: black;
    margin: 10px 0; /* Add some space above and below the text */
    text-align: center; /* Center the text */
    padding: 0 25%; /* Add some padding to the sides for better readability */

  }

  .story-text-main {

    line-height: 1;
    color: black;
    margin: 40px 0; /* Add some space above and below the text */
    text-align: center; /* Center the text */

    padding: 0 10%; /* Add some padding to the sides for better readability */
    left: 50px;
    right: 50px;
    font-family: "Caveat Brush", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 4.5rem

  }

  .App-link {
    color: #61dafb;
  }

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .App-header h1 {
    height: 20%;
    font-family: 'Codec Pro', sans-serif;
    animation: slideInFromBottom 1.3s ease-out forwards;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2); /* Standard shadow effect */
    font-size: calc(2vw + 10px);
  }


  .storyImage{
    max-width: 100%;
    height: 100%;

  }


  .cta-section {

    display: flex;
    gap: 30px;
    flex-direction: row; /* Arrange items in a row */
    align-items: center; /* Align items vertically center */
    justify-content: space-between; /* Add space between items */
    padding:  20px 0;
  }

  .artist-image-container {
    flex: 1; /* Take up equal space */
    display: flex;
    justify-content: flex-start; /* Align items to the left */
  }

  .artist-image {
    width: 100%; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 20px; /* Add space between image and text */
    transform: translateY(-30%);
  }

  .cta-text-container {
    flex: 2; /* Take up more space than the image */
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center text vertically */
    align-items: flex-start; /* Align text to the left */
  }

  .cta-section h2 {
    margin-top: 0; /* Remove top margin */
    margin-bottom: 10px; /* Add bottom margin */
    text-align: left; /* Align text to the left */
    font-size: 3rem;
    color: #d8131c;
  }

  .cta-section p {
    width: 100%; /* Limit the width of the paragraph text */
    margin: 0 0 20px 0; /* Center the paragraph text and add bottom margin */
    text-align: left; /* Align text to the left */
    font-size: 2.3rem;
  }

  .cta-button {
    display: inline-block; /* Make the button inline-block to adjust its size */
    margin-top: 10px; /* Add some space between the paragraph and the button */
  }
  .cta-button button {
    background-color: #d8131c; /* Set button background color to red */
    color: white; /* Set button text color to white */
    padding: 30px 40px; /* Add padding to the button */
    border: none; /* Remove button border */
    border-radius: 5px; /* Add border radius for rounded corners */
    font-size: 2rem; /* Adjust font size */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s ease; /* Add smooth transition for background color change */

    font-weight: bold; /* Make the button text bold */
  }

  .cta-button-container {
    padding: 20px 0;
    display: flex;
    justify-content: center; /* Center the button horizontally */
    width: 100%; /* Make sure it takes up the full width of the container */
  }

  .cta-button button:hover {
    background-color: darkred; /* Darken button background color on hover */
    transform: scale(1.05); /* Slightly increase the size of the button on hover */
  }

  .form-container {
    display: flex;
    flex-direction: row; /* Arrange items in a row */
    align-items: center; /* Align items to the top */
    justify-content: center; /* Center the items horizontally */
    width: 100%;
    margin-top: 5px; /* Add some space between the title image and the form container */
  }

  .side-image {
    width:40%; /* Adjust the width as needed */
    max-width: 600px;
    height: auto; /* Maintain aspect ratio */
  }



  /* Common styles for red rectangle section */
  .red-rectangle-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    color: white;
    padding: 20px;
    position: relative;
    top: 10px;

  }

  .rectangle-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    padding: 20px;
    position: relative;
  }

  .rectangle-item h2 {
    margin-bottom: 10px;
    color: #d8131c;
    position: static;
    top: auto;
  }


  .rectangle-item img.title-image {
    width: auto; /* Make the image take the full width of the container */
    height: 35%; /* Maintain aspect ratio */
    max-width: 200px; /* Adjust this value as needed to match the width of <p> */
    margin-bottom: 10px; /* Add some space between the image and the text */
    position: static;
  }

  .rectangle-item p {
    background-color: #d8131c;
    font-size: 2rem;
    padding: 40px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; /* This ensures the <p> takes up the available space */
    height: 100%; /* Ensure it takes the full height of the parent */
    border-radius: 10px; /* Add this line to make the corners round */
  }



  .rectangle-item:last-child {
    border-right: none;
  }

  /* Footer CSS */

  .footer-section {
    background-color: black;
    color: #ffffff;
    padding: 20px 0;
    text-align: center;
    margin-top: 20px;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .footer-left {
    flex: 1;
    text-align: left;
  }

  .footer-right {
    flex: 1;
    text-align: center;
  }

  .footer-right a {
    margin-left: 15px;
  }

  .social-icon {
    width: 70px;
    height: 70px;
  }

  .footer-section p {
    margin: 5px 0;
  }

  .full-width-image {
    width: 50%;
    display: flex;
    margin: 40px auto;
    top:100px;
  }

  /* Responsive design */


  @keyframes fadeIn {
    from {

      opacity: 0; /* Start with opacity 0 */
    }
    to {
      opacity: 1; /* End with full opacity */
    }
  }


  @keyframes fadeOut {
    from {
      opacity: 1; /* Start with full opacity */
    }
    to {
      opacity: 0; /* End with opacity 0 */
    }
  }


  .story-text-secondary {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 3rem;
    line-height: 1.6;
    left: 50px;
    right: 50px;
    color: black;
    margin: 20px 0;
    text-align: center;
    padding: 0 10%;
    gap:40px;

  }
  .story-text-secondary span:not(.plus) {
    font-weight: bold;
  }

  .plus {
    font-weight: normal;
  }
  .story-text-secondary span {
    display: inline-flex;
    text-align: center;
  }


  .image-opt{
    width: 50%;
    height: auto;
    align-items: center;
    margin: 0 auto 2rem;

  }

  .image-opt2{
    width: 12%;
    height: auto;
    align-items: center;

    margin: -10px auto 0;


  }

  .image-title{
    width: 60%;
    height: auto;
    align-items: center;
    margin: 1rem auto 0;


  }

  .option {
    text-align: center;
  }

  .option img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .option p {
    font-size: 1rem;
    margin: 0;
    font-family: "Caveat Brush", cursive;
    font-weight: 400;
    font-style: normal;
  }

  .artists-catalog-button {
    font-family: "Caveat Brush", cursive;
    font-weight: 400;
    font-style: normal;
    background-color: #d8131c;
    color: white;
    border: none;
    padding: 15px 10px;
    font-size: 2.5rem; /* Increased font size */
    cursor: pointer;
    margin-bottom:10px;
    border-radius: 17px;
    transition: background-color 0.3s;
    text-align: center; /* Ensures the text aligns properly within the button */
    display: inline-block; /* Ensures the button respects dimensions and alignment */
  }





  @media screen and (max-width: 1100px) {
    .rectangle-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      padding: 0 10%;
      position: relative;

    }

    .image-opt2{
      width: 20%;
      height: auto;
      align-items: center;

      margin: -10px auto 0;


    }

    .image-opt{
      width: 80%;
      height: auto;
      align-items: center;
      margin: 0 auto 2rem;

    }

    .story-text-secondary {
      align-items: center;
      font-size: 2rem;
      flex-direction: column;
      gap: 4px;
    }
    .story-text {
      font-size: 1.8rem;
      line-height: 1.6;
      color: black;
      margin: 20px 0; /* Add some space above and below the text */
      text-align: center; /* Center the text */
      padding: 0 10%; /* Add some padding to the sides for better readability */
    }
    .full-width-image {
      padding: 0 10%;
      width: 100%;
      display: flex;
      margin: 40px auto;
      top:100px;
    }
    .story-text-main {
      text-align:center;
      justify-content: center;
      font-size: 4rem;
      line-height: 1;
      color: black;
      margin: 20px 0; /* Add some space above and below the text */
 /* Center the text */
      padding: 0 10%; /* Add some padding to the sides for better readability */
      align-items: center;
    }
    .cta-section h2 {
      margin-top: 0; /* Remove top margin */
      margin-bottom: 10px; /* Add bottom margin */
      text-align: left; /* Align text to the left */
      font-size: 2rem;
      color: #d8131c;
    }

    .cta-section p {
      width: 100%; /* Limit the width of the paragraph text */
      margin: 0 0 20px 0; /* Center the paragraph text and add bottom margin */
      text-align: left; /* Align text to the left */
      font-size: 1.8rem;
    }
    .cta-button button {
      background-color: #d8131c; /* Set button background color to red */
      color: white; /* Set button text color to white */
      padding: 30px 40px; /* Add padding to the button */
      border: none; /* Remove button border */
      border-radius: 5px; /* Add border radius for rounded corners */
      font-size: 1rem; /* Adjust font size */
      cursor: pointer; /* Change cursor to pointer on hover */
      transition: background-color 0.3s ease; /* Add smooth transition for background color change */

      font-weight: bold; /* Make the button text bold */
    }

    .cta-button-container {
      padding: 10px 0;
      display: flex;
      justify-content: center; /* Center the button horizontally */
      width: 100%; /* Make sure it takes up the full width of the container */
    }

    .App-logo {
      width: 100%; /* Adjust this value as needed */

      height: 70%; /* This will maintain the aspect ratio */
      pointer-events: none;
    }
    .App-header {
      font-size: 16px; /* Set a fixed font size for better readability on smaller screens */
      justify-content: center;
      min-height: 90svh;
      height: 100%;
    }

    .App-header h1,
    .App-header p {
      font-size: 1.125rem; /* Adjust the font size for smaller screens */
    }

    .red-rectangle-section {
      grid-template-columns: repeat(1, 1fr); /* Change to two columns for smaller screens */
      gap: 10px; /* Reduce the gap between items */
      padding: 2px;
      top: -20px;
    }




    .artists-catalog-button {
      font-family: "Caveat Brush", cursive;
      font-weight: 400;
      font-style: normal;
      background-color: #d8131c;
      color: white;
      border: none;
      padding: 15px 10px;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 17px;
      transition: background-color 0.3s;
      align-items: center;
    }



    .rectangle-item p {
      width: 100%;
      display: flex;
      align-content: center;
      font-size: 1.2rem;
    }


    .artist-image {
      width: 100%; /* Adjust the width as needed */
      height: auto; /* Maintain aspect ratio */
      margin-right: 20px; /* Add space between image and text */
      transform: translateY(-20%);
    }
    .footer-content {
      flex-direction: column;
    }

    .footer-left, .footer-right {
      text-align: center;
      margin: 10px 0;
    }

    .form-container {
      display: flex;
      flex-direction: column-reverse; /* Arrange items in a row */
      align-items: center; /* Align items to the top */
      justify-content: center; /* Center the items horizontally */
      width: 100%;
      margin-top: 5px; /* Add some space between the title image and the form container */
      gap: 20px
    }
    .side-image {
      width:70%; /* Adjust the width as needed */

    }

  }
  @media screen and (max-width: 800px) {
    .title-image{
      margin-top:50px;
      width: 100%;
    }


    .story-text {
      font-size: 1.5rem;
      line-height: 1.6;
      color: black;
      margin: 20px 0; /* Add some space above and below the text */
      text-align: center; /* Center the text */
      padding: 0 10%; /* Add some padding to the sides for better readability */
    }
    .story-text-secondary {
      font-size: 1.6rem;
      flex-direction: column;
      gap: 4px;
    }

    .story-text-main {
      font-size: 3rem;
      line-height: 1;
      color: black;
      margin: 20px 0; /* Add some space above and below the text */
      text-align: center; /* Center the text */
      padding: 0 10%; /* Add some padding to the sides for better readability */
    }

    .cta-section h2 {
      margin-top: 0; /* Remove top margin */
      margin-bottom: 10px; /* Add bottom margin */
      text-align: left; /* Align text to the left */
      font-size: 1.6rem;
      color: #d8131c;
      padding: 0 10%;
    }

    .cta-section p {
      width: 100%; /* Limit the width of the paragraph text */
      margin: 0 0 20px 0; /* Center the paragraph text and add bottom margin */
      text-align: left; /* Align text to the left */
      font-size: 1.5rem;
      padding: 0 10%;
    }

    .cta-button {
      display: inline-block; /* Make the button inline-block to adjust its size */
      margin-top: 10px; /* Add some space between the paragraph and the button */
    }
    .cta-button button {
      background-color: #d8131c; /* Set button background color to red */
      color: white; /* Set button text color to white */
      padding: 30px 40px; /* Add padding to the button */
      border: none; /* Remove button border */
      border-radius: 5px; /* Add border radius for rounded corners */
      font-size: 1rem; /* Adjust font size */
      cursor: pointer; /* Change cursor to pointer on hover */
      transition: background-color 0.3s ease; /* Add smooth transition for background color change */
      transform: translateY(-30%);
      font-weight: bold; /* Make the button text bold */
    }
    .side-image {
      width:70%; /* Adjust the width as needed */

    }
  }

  @media screen and (max-width: 550px) {
    .cta-section h2 {
      text-align: center;
      font-size: 1.8rem;

    }

    .social-icon {
      width: 40px;
      height: 40px;
    }


    .image-opt2{
      width: 40%;
      height: auto;
      align-items: center;

      margin: -10px auto 0;


    }
    .cta-section p {

      text-align: center;
      font-size: 1.6rem;
    }

    .cta-section {
      flex-direction: column;
      gap:2px;
      padding: 10px 0;
    }

    .story-text{
      font-size: 1.5rem;
      margin: 8px 0;
    }

    .story-text-main {
      font-size: 3rem;
      margin: 40px 0;
    }
    .artist-image-container{
      width: 40%;
      transform: translateX(-55%);
    }

    .cta-button-container {
      justify-content: center;
    }


    .cta-button a {
      text-decoration: none; /* Ensure links within the button have no underline */
      color: inherit; /* Ensure links within the button inherit the button text color */
    }
    .cta-button button {
      align-items: center;
      background-color: #d8131c;
      border: none;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      display: flex;
      font-size: 1.5rem;
      font-weight: 700;
      padding: 30px 40px;
      transition: background-color .3s ease;
      text-decoration: #d8131c; /* Remove text underline */
    }

    .cta-text-container {
      align-items: center;
    }
    .side-image {
      width:70%; /* Adjust the width as needed */

    }
    .image-title{
      width: 80%;
      height: auto;
      align-items: center;
      margin: 1rem auto 0;


    }


  }

  @media screen and (max-width: 400px) {
    .cta-section h2 {

      font-size: 1.5rem;

    }
    .cta-section {
      gap:2px;
      padding: 10px 0;
    }
    .cta-section p {


      font-size: 1rem;
    }

    .story-text {
      font-size: 1rem;

    }



    .cta-button button {

      font-size: 1.3rem; /* Adjust font size */
      cursor: pointer; /* Change cursor to pointer on hover */
      transition: background-color 0.3s ease; /* Add smooth transition for background color change */
      transform: translateY(-40%);
      font-weight: bold; /* Make the button text bold */
      padding: 20px 30px;
    }

  }
  @media screen and (max-height: 600px) {
    .App-logo {
      width: 100%; /* Adjust this value as needed */

      height: 70%; /* This will maintain the aspect ratio */
      pointer-events: none;
    }
    .App-header {
      font-size: 16px; /* Set a fixed font size for better readability on smaller screens */
      justify-content: center;
    }

    .App-header h1,
    .App-header p {
      font-size: 1.125rem; /* Adjust the font size for smaller screens */
    }

    .storyImage{
      width: 100%;
      height: auto;
    }



    .form-container {
      display: flex;
      flex-direction: column-reverse; /* Arrange items in a row */
      align-items: center; /* Align items to the top */
      justify-content: center; /* Center the items horizontally */
      width: 100%;
      margin-top: 5px; /* Add some space between the title image and the form container */
    }
    .side-image {
      width:70%; /* Adjust the width as needed */

    }





  }

  @media screen and (max-width: 380px) {
    .red-rectangle-section {
      grid-template-columns: repeat(1, 1fr); /* Change to a single column for very small screens */
    }







  }



