.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    font-family: 'Lexend Deca', sans-serif;
}

.navbar-container {
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    position: relative; /* Add relative positioning */
}

/* Center the logo horizontally */
.navbar-logo {
    display: flex;
    align-items: center;
    height: 100%;
}

/* Set a maximum height for the logo */
.navbar-logo img {
    height: 100%; /* Set height to 100% of the navbar */
    width: auto; /* Maintain aspect ratio */
    right: 25%;
    max-height: 100%; /* Ensure the logo doesn't exceed the navbar height */
}

.navbar-logo-text {
     color: #fff;
     font-size: 2rem;
 }

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: flex; /* Change to flex for horizontal alignment */
    list-style: none;
    text-align: center;
    justify-content: flex-end; /* Align items to the right */
    align-items: center; /* Align items vertically */
}

.nav-item {
    height: 50px;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    height: 100%;
}

.nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: block;
    position: absolute; /* Position the menu-icon */
    top: 50%; /* Move it to the middle vertically */
    right: 20px; /* Adjust as needed */
    transform: translateY(-50%); /* Center it vertically */
    cursor: pointer;
}
@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    /* Hamburger menu icon */
    .menu-icon {
        position: relative;
        top: 20%;
        left: 40px;
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 30px;
        height: 20px;
    }

    .menu-icon div {
        width: 100%;
        height: 3px;
        background-color: #fff;
        border-radius: 5px;
        transition: all 0.3s ease;
    }

    .nav-item {
        height: auto;
    }

    /* Active state styles for hamburger menu */
    .menu-icon.active div:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .menu-icon.active div:nth-child(2) {
        opacity: 0;
    }

    .menu-icon.active div:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-menu {
        opacity: 0;
        position: absolute;
        display: flex;
        top: 60px;
        left: 0;
        padding:10px;
        width: 40%;
        height: calc(100vh - 80px);
        overflow-y: unset;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        transform: translateX(-100%);
        z-index: 999;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
    }


    .nav-menu.active {
        opacity: 1;
        transform: translateX(0);
        transition: transform 1s ease-in-out;
    }

    /* Navbar.css */
    .overlay {
        position: absolute;
        top: 80px;
        right: 0;
        width: 100%;
        height: calc(100vh - 80px);
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 998;
    }
    .nav-menu li {
        margin: 2px 0;
    }

    .nav-links {
        color: #fff;
        text-decoration:  crimson;
        margin: 2px;
        transition: color 0.3s ease-in-out, font-size 0.2s ease-in-out;

    }

    .nav-links:hover {
        color: red;
        font-size: 2rem;
    }

}
@media screen and (max-width: 768px) {
    .nav-links {
        font-size: 1rem;
    }

    .nav-links:hover {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 480px) {
    .nav-links {
        font-size: 0.7rem;
    }

    .nav-links:hover {
        font-size: .8rem;
    }
}

